/* eslint-disable complexity */
import React, { useContext } from 'react';

import { ModalContext } from 'context/ModalContext';
import { RewardsType, RewardsFromRestaurantType } from 'types/api';
import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';
import { MODAL_TYPE } from 'helpers/modals';
import getRewardsCopyAndModalConfig from 'helpers/rewards';

import { RingProgress, UnstyledButton } from '@mantine/core';

import AchievedRewardSticker from 'svgs/AchievedRewardSticker';
import BannerSticker from 'svgs/BannerSticker';

import styles from './styles.module.scss';

interface RewardsBannerProps {
  isLoggedIn: boolean;
  rewards: RewardsType[];
  rewardsFromRestaurant: RewardsFromRestaurantType;
}

export default function RewardsBanner({
  isLoggedIn,
  rewards,
  rewardsFromRestaurant,
}: RewardsBannerProps) {
  const { openModal } = useContext(ModalContext);

  const {
    messages,
    numQualifiedOrders,
    orderThreshold,
    programDescription,
    progressValue,
    rewardMet,
    rewardsInProgress,
    rewardsProgress,
  } = getRewardsCopyAndModalConfig({ rewards, rewardsFromRestaurant });

  const ctaCopy = isLoggedIn
    ? 'Place an order to start earning rewards.'
    : 'Log in to start earning rewards.';
  const copy = rewardsInProgress
    ? rewardsProgress
    : `${programDescription} ${ctaCopy}`;

  const progressRingOrRibbon = rewardMet ? (
    <AchievedRewardSticker />
  ) : (
    <RingProgress
      size={42}
      thickness={3}
      roundCaps
      rootColor="#DAD8D2"
      sections={[{ value: progressValue, color: '#0E7A4A' }]}
    />
  );
  const leftVisualElement = rewardsInProgress ? (
    progressRingOrRibbon
  ) : (
    <div className={styles.takeoutSticker}>
      <BannerSticker />
    </div>
  );

  function showRewardsModal() {
    openModal(MODAL_TYPE.rewards, {
      isLoggedIn,
      messages,
      numQualifiedOrders,
      orderThreshold,
      rewardMet,
      rewardsProgress,
    });

    logAnalyticsEvent({
      eventName: ANALYTICS_EVENT_NAME.selectX,
      attributes: {
        name: 'learn-more-loyalty',
        source: 'menu',
      },
    });
  }

  return (
    <>
      <div className={styles.stickerOrProgress}>{leftVisualElement}</div>
      <div className={styles.rewardDescription}>
        <div className={styles.promoDescriptionWrapper}>
          <p className={styles.rewardDescription}>
            {copy} {rewardMet && 'Applied at checkout.'}&nbsp;
          </p>
          <UnstyledButton
            className={styles.learnMoreBtn}
            onClick={showRewardsModal}
          >
            Learn More
          </UnstyledButton>
        </div>
      </div>
    </>
  );
}
