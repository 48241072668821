/* eslint-disable complexity */
import React, { useEffect, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { isEmpty } from 'lodash';
import classNames from 'classnames';
import { format } from 'date-fns';
import { parse } from 'query-string';

import { postToChownowApi } from 'helpers/api';
import { isWithin30Days } from 'helpers/date';
import { ACCOUNT_MODAL_TYPE, MODAL_TYPE } from 'helpers/modals';
import { MEMBERSHIP_STATUS } from 'helpers/constants';
import { formatPhone } from 'helpers/format';
import { getDateForApiDateStr } from '@chownow/cn-web-utils/date';
import { ANALYTICS_EVENT_NAME, logAnalyticsEvent } from 'helpers/loggers';
import { getSubmitOrderCount } from 'helpers/order';
import { NEW_CARD_VALUE } from 'helpers/payments';
import { buildRoute, ROUTES } from 'helpers/routes';

import useRequiresAuth from 'hooks/useRequiresAuth';
import usePlatform from 'hooks/usePlatform';

import { ModalContext } from 'context/ModalContext';
import { UserContext } from 'context/UserContext';

import Navbar from 'components/Navbar';
import ScrollToTop from 'components/ScrollToTop';
import CreditCardDisplay from 'components/CreditCardDisplay';
import Footer from 'components/Footer';

import Button from 'primitives/Button';
import ButtonLink from 'primitives/ButtonLink';
import Grid from 'primitives/Grid';
import LoadingSpinner from 'primitives/LoadingSpinner';
import Switch from 'primitives/Switch';

import RewardPrograms from './RewardPrograms/RewardPrograms.tsx';

import styles from './styles.module.scss';

function getMembershipRoute(membership) {
  return buildRoute({
    hqId: membership.company_id,
    restaurantId: membership.restaurant_id,
    route: ROUTES.membershipPlans,
  });
}

function Account() {
  const auth = useRequiresAuth();
  const location = useLocation();
  const queryParams = parse(location.search);
  const { company } = queryParams;

  const { openModal, closeModal } = useContext(ModalContext);
  const {
    cardForm,
    user,
    setUser,
    rewards,
    handleFetchRewards,
    handleFetchUserProfile,
  } = useContext(UserContext);

  const hasMemberships = user && user.memberships && !!user.memberships.length;

  const hasRewards = rewards && !!rewards.length;

  const { isMarketplacePlatform } = usePlatform();

  const [isUpdating, setIsUpdating] = useState(false);

  const submittedOrderCount = getSubmitOrderCount();

  const savedAddresses = user.delivery?.addresses || [];
  const savedCreditCards = user.billing?.cards || [];

  useEffect(() => {
    const isMissingInfo = user && (!user.last_name || !user.phone);

    // Wait until auth is fully loaded to check isMissing
    if (auth && isMissingInfo) {
      openModal(MODAL_TYPE.confirmAccount, { noCloseOverlay: true });
    }
  }, [auth, user]);

  useEffect(() => {
    if (company) {
      handleFetchRewards({ param: 'company_id', id: company });
    } else {
      handleFetchRewards({});
    }
  }, [company]);

  async function handleOptIn() {
    setIsUpdating(true);
    const { error } = await postToChownowApi({
      endpoint: `customer/${user.id}`,
      body: {
        is_optout: !user.is_optout,
      },
      method: 'PUT',
    });

    if (!error) {
      // mParticle
      if (window.mParticle) {
        try {
          const currentUser = window.mParticle.Identity.getCurrentUser();

          currentUser?.setUserAttribute('user_is_optout', user.is_optout);
        } catch (err) {
          // fail silently when error occurs
        }
      }

      setUser({ ...user, is_optout: !user.is_optout });
    }
    setIsUpdating(false);
  }

  async function handleRewardsOptOut(restaurantId) {
    setIsUpdating(true);
    const { error } = await postToChownowApi({
      endpoint: `customer/${user.id}/rewards-consent`,
      body: {
        opted_in: false,
        restaurant_id: restaurantId,
      },
      method: 'PUT',
    });

    if (!error) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.selectX,
        attributes: {
          name: 'opt-out-loyalty',
          source: 'settings',
        },
      });

      closeModal();
    }

    setIsUpdating(false);

    if (company) {
      handleFetchRewards({ param: 'company_id', id: company });
    } else {
      handleFetchUserProfile();
    }
  }

  function handleOpenModal(modalType, address) {
    openModal(MODAL_TYPE.account, {
      accountModalType: modalType,
      address,
    });
  }

  function handleOpenPayments(cardId) {
    openModal(MODAL_TYPE.payment, { cardId, cardForm });
  }

  function getShouldShowRenewButton(membership) {
    // if the membership is active or manually canceled, do not show Renew button
    if (
      membership.is_active ||
      membership.status === MEMBERSHIP_STATUS.canceled
    ) {
      return false;
    }

    const isMembershipExpired = membership.status === MEMBERSHIP_STATUS.expired;
    const isExpiredWithin30Days = isWithin30Days(membership.expires_at);
    if (!(isMembershipExpired && isExpiredWithin30Days)) return false;

    // userMemberships gives all memberships a user has
    // filter through to group by similar restaurantId
    const membershipGroup = user.memberships.filter(
      (plan) => plan.restaurant_id === membership.restaurant_id
    );
    const hasAnotherActiveMembership = membershipGroup.some(
      (plan) => plan.is_active
    );

    // if they have new active membership, do not show Renew button
    return !hasAnotherActiveMembership;
  }

  if (!auth) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Helmet>
        <title>Account Settings</title>
      </Helmet>
      {isUpdating && (
        <div className={styles.fixedSpinner}>
          <LoadingSpinner overlay />
        </div>
      )}

      <ScrollToTop />
      <div className={styles.accountWrapper}>
        <div>
          <Navbar />
          <div className={styles.header}>
            <Grid
              container
              className={styles.heading}
              data-testid="AccountTitle"
            >
              <Grid sm={4} md={12} lg={10} lgOffset={2}>
                <span data-testid="AccountTitle">Account Settings</span>
              </Grid>
            </Grid>
          </div>
          <Grid container className={styles.accountContent}>
            <Grid sm={4} md={12} lg={10} lgOffset={2}>
              <div className={styles.section}>
                <div className={styles.sectionHeading}>Account Details</div>
                <div className={styles.sectionContent}>
                  <div>
                    <div className={styles.label}>Name</div>
                    <div
                      className={styles.labelDetails}
                      data-testid="CustomerName"
                    >
                      {user.first_name} {user.last_name}
                    </div>
                  </div>
                  <ButtonLink
                    dataTestId="EditName"
                    onClick={() => handleOpenModal(ACCOUNT_MODAL_TYPE.name)}
                    className={styles.editButton}
                  >
                    Edit
                  </ButtonLink>
                </div>
                <div className={styles.sectionContent}>
                  <div>
                    <div className={styles.label}>Email</div>
                    <div
                      className={classNames(
                        styles.labelDetails,
                        styles.disabled
                      )}
                      data-testid="CustomerEmail"
                    >
                      {user.email}
                    </div>
                  </div>
                </div>
                <div className={styles.sectionContent}>
                  <div>
                    <div className={styles.label}>Phone Number</div>
                    <div
                      className={styles.labelDetails}
                      data-testid="CustomerPhone"
                    >
                      {user.phone ? formatPhone(user.phone.number) : ''}
                    </div>
                  </div>
                  <ButtonLink
                    dataTestId="EditPhone"
                    onClick={() => handleOpenModal(ACCOUNT_MODAL_TYPE.phone)}
                    className={styles.editButton}
                  >
                    Edit
                  </ButtonLink>
                </div>
              </div>
              <div className={styles.section}>
                <div
                  className={classNames(styles.sectionHeading, {
                    [styles.noBorder]: isEmpty(savedAddresses),
                  })}
                >
                  Address
                </div>
                <div className={styles.addressWrapper}>
                  {savedAddresses.map((address) => (
                    <div className={styles.sectionContent} key={address.id}>
                      <div>
                        <div className={styles.address}>
                          <div>
                            {address.street_address1}
                            {address.street_address2 &&
                              `, ${address.street_address2}`}
                          </div>
                          <div>
                            {address.city}, {address.state} {address.zip}
                          </div>
                          {address.delivery_instructions && (
                            <div className={styles.deliveryInstructions}>
                              {address.delivery_instructions}
                            </div>
                          )}
                        </div>
                      </div>
                      <ButtonLink
                        dataTestId="EditAddress"
                        onClick={() =>
                          handleOpenModal(
                            ACCOUNT_MODAL_TYPE.updateAddress,
                            address
                          )
                        }
                        className={styles.editButton}
                      >
                        Edit
                      </ButtonLink>
                    </div>
                  ))}
                </div>
                <Button
                  size="small"
                  onClick={() => handleOpenModal(ACCOUNT_MODAL_TYPE.addAddress)}
                  className={styles.addButton}
                  dataTestId="AddNewAddress"
                >
                  Add Address
                </Button>
              </div>
              <div className={styles.section}>
                <div
                  className={classNames(styles.sectionHeading, {
                    [styles.noBorder]: isEmpty(savedCreditCards),
                  })}
                >
                  Payment Method
                </div>
                <div className={styles.paymentsWrapper}>
                  {savedCreditCards.map((card) => {
                    const isDeclined = !!submittedOrderCount?.ids.includes(
                      card.id
                    );
                    return (
                      <div className={styles.sectionContent} key={card.id}>
                        <div className={styles.cardInfo}>
                          <CreditCardDisplay card={card} />
                          {!card.cvv_valid && (
                            <span className={styles.cardError}>
                              {isDeclined ? '(Declined)' : '(CVV needed)'}
                            </span>
                          )}
                        </div>
                        <ButtonLink
                          dataTestId="EditCard"
                          onClick={() => handleOpenPayments(card.id)}
                          className={styles.editButton}
                        >
                          Edit
                        </ButtonLink>
                      </div>
                    );
                  })}
                </div>
                <Button
                  size="small"
                  onClick={() => handleOpenPayments(NEW_CARD_VALUE)}
                  className={styles.addButton}
                  dataTestId="AddNewCard"
                >
                  Add Card
                </Button>
              </div>
              <div className={styles.section}>
                <div className={styles.sectionHeading}>Notification</div>
                <div className={styles.sectionContent}>
                  <div>
                    <div className={styles.label}>Promo</div>
                    <p className={styles.labelDetails}>
                      I want to receive promotional messages from ChowNow and
                      ChowNow restaurant partners, and I can unsubscribe at any
                      time.
                    </p>
                  </div>
                  <div className={styles.switch}>
                    <Switch
                      isToggled={!user.is_optout}
                      onToggle={handleOptIn}
                    />
                  </div>
                </div>
              </div>
              {hasMemberships && (
                <div className={styles.section}>
                  <div className={styles.sectionHeading}>
                    Restaurant Membership
                  </div>
                  {user.memberships.map((membership) => {
                    const expiresBy = format(
                      getDateForApiDateStr(membership.expires_at),
                      'MMMM d, yyyy'
                    );
                    const shouldShowRenewButton =
                      getShouldShowRenewButton(membership) &&
                      !isMarketplacePlatform;

                    return (
                      <div
                        className={styles.sectionContent}
                        key={membership.id}
                      >
                        <div>
                          <div
                            className={classNames(
                              styles.labelDetails,
                              styles.extraPadding
                            )}
                          >
                            <span className={styles.bold}>
                              {membership.restaurant_name}
                            </span>{' '}
                            | {membership.restaurant_street_address1}
                          </div>
                          <div className={styles.labelDetails}>
                            {membership.percent}% off all orders
                          </div>
                          <div className={styles.labelSubdetails}>
                            Valid through {expiresBy}
                          </div>
                        </div>
                        {shouldShowRenewButton && (
                          <a
                            href={getMembershipRoute(membership)}
                            target="_blank"
                            rel="noreferrer"
                            membership
                            className={styles.renewButton}
                          >
                            Renew
                          </a>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}
              {hasRewards && (
                <div className={styles.section}>
                  <RewardPrograms
                    rewards={rewards}
                    handleRewardsOptOut={handleRewardsOptOut}
                  />
                </div>
              )}
            </Grid>
          </Grid>
        </div>
        <div className={styles.section}>
          <Footer />
        </div>
      </div>
    </>
  );
}

export default Account;
