import useFeatureFlagRequest from 'hooks/useFeatureFlagRequest';

import { FEATURE_FLAGS } from 'helpers/constants';

// https://chownow.atlassian.net/browse/CN-39865

function useAllowDefaultMods() {
  const { showFeature: canAllowDefaultMods } = useFeatureFlagRequest({
    doLoad: true,
    flag: FEATURE_FLAGS.allowDefaultMods,
  });

  return canAllowDefaultMods;
}

export default useAllowDefaultMods;
