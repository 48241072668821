/* eslint-disable complexity */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { ModalContext } from 'context/ModalContext';

import { AUTH_MODAL_TYPE, MODAL_TYPE } from 'helpers/modals';
import useFetchManifest from 'hooks/useFetchManifest';

import Modal from 'primitives/Modals/Modal';
import ModalFooter from 'primitives/Modals/ModalFooter';
import Button from 'primitives/Button';
import Outlink from 'primitives/Outlink';

import AchievedRewardSticker from 'svgs/AchievedRewardSticker';
import Sticker from 'svgs/Sticker';
import RewardSticker from 'svgs/RewardSticker';
import RewardPlaceholder from 'svgs/RewardPlaceholder';

import { getEarnedStickers, getPlaceholderStickers } from './helpers';

import styles from './styles.module.scss';

export default function RewardsModal({
  isLoggedIn,
  messages,
  numQualifiedOrders,
  orderThreshold,
  rewardMet,
  rewardsProgress,
}) {
  const { closeModal, openModal } = useContext(ModalContext);

  const { rewards_terms_url: rewardsTermsUrl } = useFetchManifest();

  const {
    learn_more_description: learnMoreDescription,
    learn_more_title: learnMoreTitle,
  } = messages;

  const modalTitle =
    !isLoggedIn || !numQualifiedOrders
      ? 'Collect stickers and start earning rewards!'
      : rewardsProgress;
  const buttonCopy = isLoggedIn ? 'Sounds Good' : 'Log In to Earn';
  const numberOfQualifiedStickers = rewardMet
    ? numQualifiedOrders - 1
    : numQualifiedOrders || 1;
  const numOfPlaceholderStickers =
    orderThreshold - numberOfQualifiedStickers - 1;
  const earnedStickers = getEarnedStickers(numberOfQualifiedStickers);
  const placeholderStickers = getPlaceholderStickers(numOfPlaceholderStickers);
  const lastSticker = rewardMet ? (
    <AchievedRewardSticker />
  ) : (
    <RewardPlaceholder />
  );

  const fiveStickerFormat = orderThreshold <= 5;
  const eightStickerFormat = orderThreshold > 5 && orderThreshold <= 8;

  function handleButtonClick() {
    closeModal();

    if (!isLoggedIn) {
      openModal(MODAL_TYPE.auth, {
        activeAuthModal: AUTH_MODAL_TYPE.loginSignUp,
      });
    }
  }

  return (
    <Modal>
      <div className={styles.centeredTitle}>{modalTitle}</div>
      <div className={styles.stickerContainer}>
        <div
          className={classNames(styles.stickerSheet, {
            [styles.fiveStickers]: fiveStickerFormat,
            [styles.eightStickers]: eightStickerFormat,
          })}
        >
          {earnedStickers}
          {placeholderStickers}
          {lastSticker}
        </div>
      </div>
      <div className={styles.contentDivider}>How it works</div>
      <div className={styles.item}>
        <div className={styles.sticker}>
          <Sticker />
        </div>
        {learnMoreTitle}
      </div>
      <div className={styles.item}>
        <div className={styles.sticker}>
          <RewardSticker />
        </div>
        {learnMoreDescription}
      </div>
      <ModalFooter>
        <Button
          onClick={handleButtonClick}
          className={styles.modalButton}
          isFullWidth
        >
          {buttonCopy}
        </Button>
        <div className={styles.terms}>
          By participating in our rewards program, you agree to our{' '}
          <Outlink
            to={rewardsTermsUrl}
            ariaLabel="ChowNow Terms"
            className={styles.termsLink}
          >
            Terms
          </Outlink>
          .
        </div>
      </ModalFooter>
    </Modal>
  );
}

RewardsModal.propTypes = {
  isLoggedIn: PropTypes.bool.isRequired,
  messages: PropTypes.shape({
    learn_more_description: PropTypes.string.isRequired,
    learn_more_title: PropTypes.string.isRequired,
  }).isRequired,
  numQualifiedOrders: PropTypes.number,
  orderThreshold: PropTypes.number.isRequired,
  rewardMet: PropTypes.bool.isRequired,
  rewardsProgress: PropTypes.string,
};

RewardsModal.defaultProps = {
  numQualifiedOrders: 0,
  rewardsProgress: '',
};
