/* eslint-disable complexity */
import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { find, partial, some, pick } from 'lodash';

import { InView } from 'react-intersection-observer';
import {
  ATTRIBUTES,
  orderAttributes,
  restaurantAttributes,
} from 'helpers/analytics';
import {
  logAnalyticsCommerceEvent,
  logAnalyticsEvent,
  ANALYTICS_EVENT_NAME,
  ANALYTICS_EVENT_TYPES,
  createAnalyticsProduct,
} from 'helpers/loggers';
import {
  calculateModifierPrice,
  getFlatModIds,
  getNestedSelections,
  getUpdatedSelections,
} from 'helpers/order';
import { menuImageType } from 'helpers/prop-types';
import { MAX_LENGTH, REORDER_CATEGORY_NAME } from 'helpers/constants';
import { getIsDirectToMpRedirect } from 'helpers/configureRedirects';

import useAllowDefaultMods from 'hooks/featureFlags/useAllowDefaultMods';

import { getRestaurantDetails } from 'modules/restaurant';
import {
  getOrderItemData,
  removeOrderItemRequest,
  getOrderData,
} from 'modules/order';

import useIsMobile from 'hooks/useIsMobile';

import { CompanyContext } from 'context/CompanyContext';
import { MenuContext } from 'context/MenuContext';
import { ModalContext } from 'context/ModalContext';

import MenuItemImage from 'components/MenuItemImage';
import ModCategory from 'components/ModCategory';

import IconClose from 'svgs/IconClose';

import ItemHeader from './ItemHeader';
import ItemFooter from './ItemFooter';
import NestedModifier from './NestedModifier';

import styles from './styles.module.scss';

function MenuItemModal(props) {
  const {
    categoryId,
    categoryName,
    defaultSizeId,
    description,
    id,
    image,
    isMenuPreview,
    isOneClick,
    itemIndex,
    modifierCategories: existingModifierCategories, // User selected modifiers
    modifierData,
    name,
    onAddItem,
    onModalClose,
    price,
    quantity,
    recentlyOrderedItem,
    sizeId, // User selected size, only shows on editing item
    specialInstructions,
    tracking_id: trackingId,
  } = props;

  const canAllowDefaultMods = useAllowDefaultMods();

  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { isCanadian } = useContext(CompanyContext);
  const { closeModal } = useContext(ModalContext);
  const {
    menu,
    getCompleteModifierDataForItem,
    getSizeDataForItem,
    getNestedModData,
    getParentModifierName,
  } = useContext(MenuContext);

  const itemModalRef = React.createRef();

  const itemsData = useSelector(getOrderItemData);
  const restaurant = useSelector(getRestaurantDetails);
  const order = useSelector(getOrderData);
  // check to see if selected item has isOneClick attribute (used for mParticle logging edit items)
  const getIsOneClick =
    trackingId &&
    categoryName === REORDER_CATEGORY_NAME &&
    !!itemsData.find((item) => item.id === id).isOneClick;

  // if menu item is from recently ordered carousel, we log the source of the click: either
  // from the one click reorder button or if user clicks on menu card to bring up details modal
  const menuItemSource =
    categoryName === REORDER_CATEGORY_NAME &&
    (getIsOneClick ? 'one_click_reorder' : 'reorder_carousel');

  const rootModifierCategories = getCompleteModifierDataForItem(id);

  const sizes = getSizeDataForItem(id);

  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [updatedQuantity, setUpdatedQuantity] = useState(quantity || 1);
  const [updatedSizeId, setUpdatedSizeId] = useState(sizeId || defaultSizeId);
  const [updatedSpecialInstructions, setUpdatedSpecialInstructions] = useState(
    specialInstructions || ''
  );
  const [modifierCategoryError, setModifierCategoryError] = useState(null);
  const [isIntersecting, setIsIntersecting] = useState(null);
  const [hasImageError, setHasImageError] = useState(false);

  const [selectedNestedMod, setSelectedNestedMod] = useState({});
  const [showNestedMod, setShowNestedMod] = useState(false);
  const [currentSelectionBranch, setCurrentSelectionBranch] = useState([]);
  const [currentMods, setCurrentMods] = useState(
    existingModifierCategories || []
  );
  const [nestedErrors, setNestedErrors] = useState([]);
  const [isCheckingNestedMod, setIsCheckingNestedMod] = useState(false);

  const selectedSize = find(sizes, { id: updatedSizeId }) || {};
  const itemPrice = 'price' in selectedSize ? selectedSize.price : price;
  const modifierPrice = calculateModifierPrice(currentMods, modifierData);
  const calculatedPrice = (itemPrice + modifierPrice) * updatedQuantity;
  const allowSpecialInstructions =
    restaurant?.display_properties?.enable_special_instructions;
  const specialInstructionsExample =
    restaurant?.display_properties?.special_instructions_text;
  // only show sticky header if menu item name is not showing and if the window height is
  // over 350. if the window height is under 350, showing the header makes the modal unusable
  const showHeader =
    isIntersecting !== null && !isIntersecting && window.innerHeight > 350;

  // FIXME: is there a way to make this more generic so it can be root or nested selections?
  const nestedSelections = getNestedSelections(
    currentSelectionBranch,
    currentMods
  );

  function getErrors(modSelections, modCategories) {
    return modCategories.filter((categoryData) => {
      const selectedModifiers =
        find(modSelections, { id: categoryData.id })?.modifiers || [];
      const invalidMin =
        categoryData.min_qty && selectedModifiers.length < categoryData.min_qty;
      const invalidMax =
        categoryData.max_qty && selectedModifiers.length > categoryData.max_qty;
      return invalidMin || invalidMax;
    });
  }

  function logAddToCartEvent(addAttributes) {
    const product = [
      createAnalyticsProduct(
        name,
        id,
        price.toFixed(2),
        quantity,
        categoryName,
        isCanadian ? 'CAD' : 'USD'
      ),
    ];

    const defaultAttributes = {
      item_has_photo: !!image,
      is_direct_to_marketplace_redirect: getIsDirectToMpRedirect(),
      is_embedded_site: window.top !== window,
      menu_id: menu.id,
      menu_item_source: menuItemSource,
      menu_item_location: !!menuItemSource && itemIndex + 1,
      ...pick(orderAttributes(order), [
        ATTRIBUTES.orderAheadDatetime,
        ATTRIBUTES.orderIsAsap,
        ATTRIBUTES.orderType,
      ]),
      ...pick(restaurantAttributes(restaurant), [
        ATTRIBUTES.restaurantBrandId,
        ATTRIBUTES.restaurantLocationCategory,
        ATTRIBUTES.restaurantLocationId,
        ATTRIBUTES.restaurantLocationName,
      ]),
    };
    const attributes = { ...defaultAttributes, ...addAttributes };

    logAnalyticsCommerceEvent(
      ANALYTICS_EVENT_NAME.addToCart,
      product,
      attributes
    );
  }

  useEffect(() => {
    if (trackingId) {
      const addAttributes = {
        is_delete: false,
        is_cart_update: true,
      };

      logAddToCartEvent(addAttributes);
    }

    return () => {
      onModalClose();
    };
  }, []);

  function scrollToModCategory(elementId) {
    const element = document.getElementById(`modCategory_${elementId}`);

    if (element) {
      element.scrollIntoView({
        block: 'center',
        behavior: 'smooth',
      });
    }
  }

  function toggleCategoryError(errorId) {
    setModifierCategoryError(errorId);
    setTimeout(() => {
      setModifierCategoryError(null);
    }, 200);
    scrollToModCategory(errorId);
  }

  function handleContinueBtn() {
    let index = currentSelectionBranch.length;
    let errors = [];
    let newPath = currentSelectionBranch;
    setIsCheckingNestedMod(true);

    while (index > 0) {
      const isCheckingCurrentView = index === currentSelectionBranch.length;

      if (isCheckingCurrentView) {
        errors = getErrors(nestedSelections, selectedNestedMod.nestedMod);

        if (errors.length) {
          setNestedErrors(errors);
          scrollToModCategory(errors[0].id);
          break;
        }
      } else {
        newPath = newPath.slice(0, -2);
        const modData = getNestedModData(newPath[newPath.length - 1]);
        errors = getErrors(getNestedSelections(newPath, currentMods), modData);

        if (errors.length) {
          setNestedErrors(errors);
          setCurrentSelectionBranch(newPath);
          setSelectedNestedMod({
            nestedMod: modData,
            parentModName: getParentModifierName(newPath[newPath.length - 1]),
          });
          scrollToModCategory(errors[0].id);
          break;
        }
      }

      index -= 2;
    }

    if (!errors.length) {
      setCurrentSelectionBranch([]);
      setShowNestedMod(false);
      setSelectedNestedMod({});
      setNestedErrors([]);
      setIsCheckingNestedMod(false);
    }
  }

  function hasItemChanged(sortedModifiers) {
    const currentModIds = getFlatModIds(existingModifierCategories);
    const updatedModIds = getFlatModIds(sortedModifiers);
    if (
      quantity !== updatedQuantity ||
      JSON.stringify(currentModIds) !== JSON.stringify(updatedModIds) ||
      sizeId !== updatedSizeId ||
      specialInstructions !== updatedSpecialInstructions
    ) {
      return true;
    }

    return false;
  }

  function handleAddItem(priceOfItem) {
    // this flag is used to determine if the user attempted to submit the form.
    // in the case where they did and there were errors, this flag is used to
    // show the red required text on the mod categories.
    setHasSubmitted(true);

    const addItemErrors = getErrors(currentMods, rootModifierCategories);

    const sortedModifiers = [];
    rootModifierCategories.forEach((value) => {
      const modifierValues = currentMods.find(
        (modCat) => modCat.id === value.id
      );

      if (modifierValues) {
        sortedModifiers.push(modifierValues);
      }
    });

    if (!addItemErrors?.length) {
      if (!updatedQuantity) {
        dispatch({
          type: removeOrderItemRequest.TYPE,
          meta: trackingId,
          payload: {
            id,
            priceOfItem,
            categoryName,
            quantity: { updatedQuantity },
          },
        });
      } else {
        // if editing the item and nothing has change, do not try to edit but just close the modal
        if (trackingId && !hasItemChanged(sortedModifiers)) {
          closeModal();
          return;
        }
        onAddItem({
          modifierCategories: sortedModifiers,
          id,
          image,
          name,
          sizeId: updatedSizeId,
          categoryId, // NOTE: undefined in Marketplace
          categoryName,
          quantity: updatedQuantity,
          price: priceOfItem,
          menuCategoryItem: null,
          specialInstructions: updatedSpecialInstructions,
          tracking_id: trackingId,
          itemIndex,
          isOneClick,
        });
      }

      // only log edit item event if trackingId is present, meaning it's been added to the cart already
      if (trackingId) {
        const itemDeleted = updatedQuantity === 0 && quantity > updatedQuantity;
        const addAttributes = {
          is_delete: itemDeleted,
          is_cart_update: !itemDeleted,
        };

        logAddToCartEvent(addAttributes);
      }

      closeModal();
    } else {
      toggleCategoryError(addItemErrors[0]?.id);
    }
  }

  function formatSelectedNestedMod(modifierId) {
    return {
      nestedMod: getNestedModData(modifierId),
      parentModName: getParentModifierName(modifierId),
    };
  }

  function handleAddModifier({
    maxQty,
    minQty,
    modifier,
    modifierCategoryId,
    selectedModifiers, // mods selected specific to the modifierCategory
  }) {
    const currentPath = [
      ...currentSelectionBranch,
      modifierCategoryId,
      modifier.id,
    ];

    // Modifier Category requirement logic
    const isRadio = maxQty === 1 && minQty === 1;
    const isSelected = some(selectedModifiers, { id: modifier.id });
    const shouldRemoveMod = isSelected && !isRadio;

    if (
      maxQty &&
      selectedModifiers.length >= maxQty &&
      !isRadio &&
      !shouldRemoveMod
    ) {
      // Reached max mods allowed
      toggleCategoryError(modifierCategoryId);

      return;
    }
    // End Modifier Category requirement logic

    if (isRadio && isSelected) {
      // If a diner selects the same radio button twice, do nothing
      return;
    }

    setCurrentMods(
      getUpdatedSelections({
        currentPath,
        isRadio, // should we combine isRadio and shouldRemoveMod into one action var??
        shouldRemoveMod,
        selections: currentMods,
      })
    );

    // Updated nested errors
    if (showNestedMod && some(nestedErrors, { id: modifierCategoryId })) {
      setNestedErrors(getErrors(nestedSelections, selectedNestedMod.nestedMod));
    }

    if (!shouldRemoveMod && modifier.modifier_categories?.length) {
      setCurrentSelectionBranch(currentPath);
    }

    // NEW LOGIC - TODO: figure out how much we need here still
    const modifierHasNestedMods = modifier.modifier_categories.length !== 0;

    if (!shouldRemoveMod && modifierHasNestedMods) {
      logAnalyticsEvent({
        eventName: ANALYTICS_EVENT_NAME.selectNestedModifier,
        eventType: ANALYTICS_EVENT_TYPES.Other,
        attributes: {},
      });
      const modifierInfo = formatSelectedNestedMod(modifier.id);
      setShowNestedMod(true);
      setSelectedNestedMod(modifierInfo);
    }
  }

  function handleIntersection(inView) {
    // wait until animation finishes before checking inView
    if (isIntersecting !== null || inView) {
      setIsIntersecting(inView);
    }
  }

  function handleSetSizeId({ modifier }) {
    setUpdatedSizeId(modifier.id);
  }

  function handleUpdateQuantity(value) {
    // used by the input to allow for manual entry of quantities
    setUpdatedQuantity(value);
  }

  function handleBackBtn() {
    const originalPath = currentSelectionBranch;
    setCurrentMods(
      getUpdatedSelections({
        currentPath: currentSelectionBranch,
        shouldRemoveMod: true,
        selections: currentMods,
      })
    );

    if (originalPath.length && originalPath.length % 2 === 0) {
      originalPath.splice(-2, 2);
    }

    setCurrentSelectionBranch(originalPath);

    if (!originalPath.length) {
      setShowNestedMod(false);
      setSelectedNestedMod({});
      setIsCheckingNestedMod(false);
    } else {
      const previousModifierId = originalPath[originalPath.length - 1];
      const previousModifier = formatSelectedNestedMod(previousModifierId);
      setSelectedNestedMod(previousModifier);
    }
  }

  function handleEditOptionsBtn(modifierId, modifierCategoryId) {
    const modifierInfo = formatSelectedNestedMod(modifierId);
    const currentPath = [
      ...currentSelectionBranch,
      modifierCategoryId,
      modifierId,
    ];
    setShowNestedMod(true);
    setSelectedNestedMod(modifierInfo);
    setCurrentSelectionBranch(currentPath);
  }

  function transformOption(option) {
    return {
      id: option.id,
      name: option.size,
      price: option.price,
    };
  }

  function updateSpecialInstructions({ target: { value } }) {
    if (updatedSpecialInstructions.length >= MAX_LENGTH.textArea) {
      setUpdatedSpecialInstructions(value.substring(0, MAX_LENGTH.textArea));
    } else {
      setUpdatedSpecialInstructions(value);
    }
  }

  return (
    <div className={styles.itemModal} data-testid="ItemModal">
      <ItemHeader
        itemName={name}
        animateHeader={showHeader}
        onModalClose={closeModal}
      />
      <div className={styles.itemModalContent} ref={itemModalRef}>
        <button
          className={classNames(styles.closeIcon, {
            [styles.hidden]: !isIntersecting,
            [styles.closeIconPhoto]: !!image,
          })}
          onClick={closeModal}
          data-testid="CloseItemModal"
          type="button"
        >
          <IconClose />
        </button>
        {!!image && (
          <div
            className={classNames(styles.imageContainer, {
              [styles.hidden]: hasImageError,
            })}
          >
            <MenuItemImage
              alt={name}
              image={image}
              width={isMobile ? window.innerWidth : 462}
              height={isMobile ? window.innerWidth : 400}
              handlePhotoError={setHasImageError}
            />
          </div>
        )}
        <InView onChange={handleIntersection} threshold={0}>
          <div
            className={classNames(styles.modalHeadingWrapper, {
              [styles.wide]: !!image,
            })}
          >
            <h3 className={styles.itemName} data-testid="ModalItemName">
              {name}
            </h3>
            {description && <p className={styles.description}>{description}</p>}
          </div>
        </InView>
        <div className={styles.modalContentPadding}>
          {showNestedMod ? (
            <NestedModifier
              currentMods={currentMods}
              selectedNestedMod={selectedNestedMod}
              onAddModifier={handleAddModifier}
              currentSelections={nestedSelections}
              handleEditOptionsBtn={handleEditOptionsBtn}
              modifierCategoryError={modifierCategoryError}
              getErrors={getErrors}
              isCheckingNestedMod={isCheckingNestedMod}
            />
          ) : (
            <>
              {!!sizes.length && (
                <div className={styles.modCategoryWrapper}>
                  <ModCategory
                    name="Choose a Size"
                    maxQty={1}
                    minQty={1}
                    modifierCategoryId="size"
                    modifiers={sizes}
                    optionTransformer={transformOption}
                    onAddModifier={handleSetSizeId}
                    selectedModifiers={[{ id: updatedSizeId }]}
                    isSizeCategory
                  />
                </div>
              )}
              {rootModifierCategories &&
                rootModifierCategories.map((modCategory) => (
                  <div
                    className={styles.modCategoryWrapper}
                    key={modCategory.id}
                  >
                    <ModCategory
                      canAllowDefaultMods={canAllowDefaultMods}
                      maxQty={modCategory.max_qty}
                      minQty={modCategory.min_qty}
                      modifierCategoryId={modCategory.id}
                      modifiers={modCategory.modifiers}
                      name={modCategory.name}
                      onAddModifier={handleAddModifier}
                      selectedModifiers={getNestedSelections(
                        [modCategory.id],
                        currentMods
                      )}
                      flashError={modifierCategoryError === modCategory.id}
                      hasError={
                        hasSubmitted &&
                        some(getErrors(currentMods, rootModifierCategories), {
                          id: modCategory.id,
                        })
                      }
                      handleEditOptionsBtn={handleEditOptionsBtn}
                      itemHasUserPrefrences={
                        !!trackingId || !!recentlyOrderedItem
                      }
                    />
                  </div>
                ))}
              {allowSpecialInstructions && (
                <>
                  <div
                    className={classNames(styles.instructionsLabel, {
                      [styles.noBorder]:
                        !sizes.length && !rootModifierCategories.length,
                    })}
                  >
                    Special Instructions
                  </div>
                  <div className={styles.textAreaWrapper}>
                    <textarea
                      className={styles.specialInstructions}
                      maxLength={MAX_LENGTH.textArea}
                      onChange={updateSpecialInstructions}
                      placeholder={specialInstructionsExample}
                      value={updatedSpecialInstructions}
                      data-testid="SpecialInstructions"
                    />
                    <div className={styles.characterCounter}>
                      {MAX_LENGTH.textArea - updatedSpecialInstructions.length}
                    </div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.modalFooterWrapper}>
        <ItemFooter
          quantity={updatedQuantity}
          handleUpdateQuantity={handleUpdateQuantity}
          handleAddItem={partial(handleAddItem, calculatedPrice)}
          price={calculatedPrice}
          trackingId={trackingId}
          isMenuPreview={isMenuPreview}
          showNestedModControls={showNestedMod}
          handleBackBtn={handleBackBtn}
          handleContinueBtn={handleContinueBtn}
        />
      </div>
    </div>
  );
}

MenuItemModal.propTypes = {
  categoryId: PropTypes.string,
  categoryName: PropTypes.string,
  defaultSizeId: PropTypes.string,
  description: PropTypes.string,
  id: PropTypes.string.isRequired,
  image: menuImageType,
  isMenuPreview: PropTypes.bool,
  isOneClick: PropTypes.bool,
  itemIndex: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  modifierCategories: PropTypes.arrayOf(PropTypes.object),
  modifierData: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  onAddItem: PropTypes.func,
  onModalClose: PropTypes.func.isRequired,
  price: PropTypes.number,
  quantity: PropTypes.number,
  recentlyOrderedItem: PropTypes.shape({}),
  sizeId: PropTypes.string,
  specialInstructions: PropTypes.string,
  tracking_id: PropTypes.string,
};

MenuItemModal.defaultProps = {
  categoryId: undefined,
  categoryName: undefined,
  defaultSizeId: null,
  description: undefined,
  image: undefined,
  isMenuPreview: false,
  isOneClick: false,
  itemIndex: undefined,
  modifierCategories: undefined,
  modifierData: undefined,
  name: undefined,
  onAddItem: () => {},
  price: undefined,
  quantity: undefined,
  recentlyOrderedItem: undefined,
  sizeId: undefined,
  specialInstructions: undefined,
  tracking_id: undefined,
};

export default MenuItemModal;
