/* eslint-disable complexity */
import React from 'react';
import PropTypes from 'prop-types';

import { itemModifierDetailType } from 'helpers/prop-types';

import Modifier from 'components/Modifier';
import Tag from 'primitives/Tag';

import { ReactComponent as IconCaution } from 'images/icon-caution.svg';

import styles from './styles.module.scss';

function ModCategory({
  canAllowDefaultMods,
  handleEditOptionsBtn,
  hasError,
  itemHasUserPrefrences,
  isSizeCategory,
  maxQty,
  minQty,
  modifierCategoryId,
  modifiers,
  name,
  onAddModifier,
  optionTransformer,
  selectedModifiers,
}) {
  function findBranch(modifierId) {
    if (selectedModifiers.length) {
      const branch = selectedModifiers.find((mod) => mod.id === modifierId);
      return branch || {};
    }

    return {};
  }

  function renderRequiredText() {
    const modCount = modifiers.length;

    if (minQty === 0 && maxQty > 0) {
      return `Optional (Up to ${maxQty})`;
    }

    if (minQty === 0) {
      return 'Optional';
    }

    // If maxQty is set to modCount in admin, API returns maxQty as null
    if (minQty === (maxQty || modCount) && minQty > 1) {
      return `Required - Choose ${minQty}`;
    }

    if (maxQty && maxQty !== modCount && maxQty > minQty) {
      return `Required - ${minQty}-${maxQty}`;
    }

    if (!maxQty && modCount > 1) {
      return `At least ${minQty}`;
    }

    return 'Required';
  }

  function getTransformedOption(option) {
    return optionTransformer ? optionTransformer(option) : option;
  }

  function getFirstDefaultId() {
    const modsWithDefault = modifiers.find((mod) => mod.is_default);
    if (modsWithDefault?.length > 0) return modsWithDefault[0]?.id;

    return modifiers[0]?.id;
  }

  return (
    <fieldset
      id={`modCategory_${modifierCategoryId}`}
      data-testid="ModifierCategory"
    >
      <div className={styles.modHeader}>
        <legend className={styles.modName}>{name}</legend>
        <span
          id="ModifierRequirement"
          data-testid="Required"
          aria-describedby={hasError ? 'required-error' : null}
          aria-invalid={hasError}
        >
          <Tag
            icon={hasError && <IconCaution className={styles.hasErrorIcon} />}
            isCaution={hasError}
            id="required-error"
          >
            {renderRequiredText()}
          </Tag>
        </span>
      </div>
      <ul className={styles.modList}>
        {modifiers.map((mod) => {
          const {
            id,
            name: modName,
            price,
            is_default: isDefault,
          } = getTransformedOption(mod);
          const isModSelected = !!selectedModifiers.find(
            (modifier) => modifier.id === mod.id
          );
          const isRadio = maxQty === 1 && minQty === 1;
          const { modifier_categories: nestedMods } = mod;

          const shouldDefaultMod =
            (!isRadio && isDefault) || (isRadio && getFirstDefaultId() === id);

          return (
            <Modifier
              disabled={
                !isRadio &&
                !isModSelected &&
                selectedModifiers.length === maxQty
              }
              categoryId={modifierCategoryId}
              handleEditOptionsBtn={() =>
                handleEditOptionsBtn(id, modifierCategoryId)
              }
              canAllowDefaultMods={canAllowDefaultMods}
              id={id}
              isRadio={isRadio}
              isSelected={isModSelected}
              isSizeMod={isSizeCategory}
              key={id}
              name={modName}
              isDefault={shouldDefaultMod && !itemHasUserPrefrences} // should only set default if user has not selected own mods
              nestedMods={nestedMods}
              branch={findBranch(id)}
              onSelectModifier={() =>
                onAddModifier({
                  maxQty,
                  minQty,
                  modifier: mod,
                  modifierCategoryId,
                  selectedModifiers,
                })
              }
              price={price}
            />
          );
        })}
      </ul>
    </fieldset>
  );
}

ModCategory.propTypes = {
  canAllowDefaultMods: PropTypes.bool,
  handleEditOptionsBtn: PropTypes.func,
  hasError: PropTypes.bool,
  itemHasUserPrefrences: PropTypes.bool,
  isSizeCategory: PropTypes.bool,
  maxQty: PropTypes.number,
  minQty: PropTypes.number,
  modifierCategoryId: PropTypes.string.isRequired,
  modifiers: PropTypes.arrayOf(itemModifierDetailType).isRequired,
  name: PropTypes.string.isRequired,
  onAddModifier: PropTypes.func.isRequired,
  optionTransformer: PropTypes.func,
  selectedModifiers: PropTypes.arrayOf(PropTypes.shape({})),
};

ModCategory.defaultProps = {
  canAllowDefaultMods: false,
  handleEditOptionsBtn: () => {},
  hasError: false,
  itemHasUserPrefrences: false,
  isSizeCategory: false,
  maxQty: undefined,
  minQty: undefined,
  optionTransformer: undefined,
  selectedModifiers: [],
};

export default ModCategory;
