/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';

import {
  getOrderData,
  resetPromoCodeRequest,
  triggerValidate,
  updateOrderRequest,
} from 'modules/order';
import {
  getIsDeliveryOnly,
  fetchRestaurantXHR,
  getRestaurantDetails,
} from 'modules/restaurant';
import {
  getUserMembershipPlan,
  getHasActiveMembership,
  getExpiredMemberships,
  getRewardsOptInStatusFromSessionStorage,
  setRewardsOptInStatusFromSessionStorage,
} from 'helpers/customer';
import {
  handleFetchLogout,
  handleFetchUserProfile,
  handleFetchUpdateUser,
  handleFetchRewards,
} from 'helpers/customerApi';
import {
  APPLE_PAY_STRUCT,
  GOOGLE_PAY_STRUCT,
  NEW_CARD_VALUE,
  creditCardStringToType,
} from 'helpers/payments';

const initialCardForm = {
  address: {
    zip: '',
  },
  cvv: '',
  expDate: '',
  is_visible: true,
  number: '',
  type: '',
};

const UserContext = createContext({
  cardForm: initialCardForm,
  handleFetchUserProfile: () => {},
  handleLogout: () => {},
  handleUpdateUser: () => {},
  isLoaded: false,
  isLoggedIn: false,
  selectedCard: [],
  selectedCardId: null,
  sessionId: '',
  setCardForm: () => {},
  setIsLoaded: () => {},
  setSelectedCardId: () => {},
  setSessionId: () => {},
  setUser: () => {},
  user: { id: '', email: '', phone: { number: '' }, is_optout: false },
});

function UserProvider(props) {
  const { children } = props;

  const dispatch = useDispatch();
  const order = useSelector(getOrderData);
  const isDeliveryOnly = useSelector(getIsDeliveryOnly);
  const restaurant = useSelector(getRestaurantDetails);
  const optInRewardsStatus = getRewardsOptInStatusFromSessionStorage();

  const [isLoaded, setIsLoaded] = useState(false);
  const [userState, setUserState] = useState({});
  const [selectedCardId, setSelectedCardId] = useState(null);
  const [sessionId, setSessionId] = useState('');
  const [cardForm, setCardForm] = useState(initialCardForm);
  const [selectedMembership, setSelectedMembership] = useState();
  const [rewards, setRewards] = useState([]);
  const [rewardsOptIn, setRewardsOptIn] = useState(optInRewardsStatus);

  const fetchUserValues = { sessionId, setUser: setUserState, setIsLoaded };
  const membershipValues = {
    userMemberships: userState.memberships || [],
    restaurant,
  };

  function getSelectedCard() {
    if (selectedCardId === NEW_CARD_VALUE) {
      return cardForm;
    }

    if (selectedCardId === APPLE_PAY_STRUCT.id) {
      return APPLE_PAY_STRUCT;
    }

    if (selectedCardId === GOOGLE_PAY_STRUCT.id) {
      return GOOGLE_PAY_STRUCT;
    }

    return find(userState.billing?.cards, { id: selectedCardId });
  }

  function handleSetCardForm(data) {
    if (!data) return setCardForm(initialCardForm);

    const { type } = data;

    return setCardForm({
      ...data,
      id: NEW_CARD_VALUE,
      type_id: creditCardStringToType[type],
      last_four: data.number.slice(-4),
    });
  }

  function fetchUser() {
    handleFetchUserProfile(fetchUserValues);
  }

  function fetchRewards({ id = '', param = '', includeOptOuts }) {
    handleFetchRewards({ id, param, includeOptOuts, setRewards });
  }

  function updateUser(values) {
    const error = handleFetchUpdateUser(values, userState, fetchUserValues);
    return error;
  }

  function handleSetRewardsOptIn({ optInStatus, restaurantId }) {
    setRewardsOptIn({ optInStatus, restaurantId });
    setRewardsOptInStatusFromSessionStorage({ optInStatus, restaurantId });
  }

  function handleLogout() {
    handleFetchLogout({
      setUser: setUserState,
      setCardForm,
      setSelectedCardId,
      setSessionId,
      order,
      isDeliveryOnly,
      dispatch,
      resetPromoCodeRequest,
      updateOrderRequest,
      fetchRestaurantXHR,
      triggerValidate,
    });
  }

  return (
    <UserContext.Provider
      value={{
        cardForm,
        handleFetchUserProfile: fetchUser,
        handleLogout,
        handleUpdateUser: updateUser,
        handleFetchRewards: fetchRewards,
        isUserLoaded: isLoaded,
        isLoggedIn: !!userState.id,
        membership: {
          userMembershipPlan: getUserMembershipPlan(membershipValues),
          hasActiveMembership: getHasActiveMembership(membershipValues),
          expiredMemberships: getExpiredMemberships(membershipValues),
          selectedMembership,
          setSelectedMembership,
        },
        rewards,
        rewardsOptIn,
        selectedCard: getSelectedCard(),
        selectedCardId,
        sessionId,
        setCardForm: handleSetCardForm,
        setIsLoaded,
        setSelectedCardId,
        setSessionId,
        setRewards,
        setRewardsOptIn: handleSetRewardsOptIn,
        setUser: setUserState,
        user: userState,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { UserContext, UserProvider };
