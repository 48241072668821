import { RewardsType, RewardsFromRestaurantType } from 'types/api';

interface RewardsHelperProps {
  rewards: RewardsType[];
  rewardsFromRestaurant: RewardsFromRestaurantType;
}

export default function getRewardsCopyAndModalConfig({
  rewards,
  rewardsFromRestaurant,
}: RewardsHelperProps) {
  const { progress_cards: progressCards } = rewards?.[0] || {};
  // If multiple progress cards show the in progress one
  const selectedProgressCard =
    progressCards?.length > 1
      ? progressCards.filter((card) => card.completed_at === null)
      : progressCards;
  const {
    num_qualified_orders: numQualifiedOrders,
    messages: messagesFromRewards,
    reward_config_data: rewardConfigData,
  } = selectedProgressCard?.[0] || {};
  const { rewards_progress: rewardsProgress } =
    selectedProgressCard?.[0]?.messages || '';
  const {
    messages: messagesFromResto,
    order_threshold: orderThresholdFromResto = 1,
  } = rewardsFromRestaurant;

  const messages = messagesFromRewards || messagesFromResto;
  const orderThreshold =
    rewardConfigData?.order_threshold || orderThresholdFromResto;
  const rewardsInProgress = progressCards?.length > 0;
  const rewardMet = numQualifiedOrders === orderThreshold;
  const progressValue = (numQualifiedOrders / orderThreshold) * 100;

  return {
    messages,
    numQualifiedOrders,
    orderThreshold,
    programDescription: messagesFromResto.program_description,
    progressValue,
    rewardMet,
    rewardsInProgress,
    rewardsProgress,
  };
}
