import React, { useContext } from 'react';

import { ModalContext } from 'context/ModalContext';
import { RewardsType } from 'types/api';
import { MODAL_TYPE } from 'helpers/modals';

import { RingProgress } from '@mantine/core';

import ButtonLink from 'primitives/ButtonLink';

import styles from './styles.module.scss';

interface RewardProgramsProps {
  rewards: RewardsType[];
  handleRewardsOptOut: (restaurantId: string) => void;
}

export default function RewardPrograms({
  rewards,
  handleRewardsOptOut,
}: RewardProgramsProps) {
  const { openModal } = useContext(ModalContext);

  const rewardsList = rewards.map((reward) => (
    <div
      key={`reward_${reward.restaurant_id}`}
      className={styles.rewardContainer}
    >
      <div className={styles.rewardHeader}>
        <div className={styles.restaurantInfo}>
          <span className={styles.restaurantName}>
            {reward.restaurant_name}&nbsp;
          </span>
          | {reward.restaurant_address}
        </div>
        <ButtonLink
          className={styles.optOut}
          onClick={() =>
            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            openModal(MODAL_TYPE.rewardsOptOut, {
              restaurantName: reward.restaurant_name,
              restaurantId: reward.restaurant_id,
              handleRewardsOptOut,
            })
          }
        >
          Opt Out
        </ButtonLink>
      </div>
      {reward.progress_cards?.length > 0 && (
        <div className={styles.progressCardsWrapper}>
          {reward.progress_cards.map((pc) => (
            <div key={pc.id} className={styles.progressInfo}>
              <RingProgress
                size={32}
                thickness={3}
                roundCaps
                rootColor="#DAD8D2"
                sections={[
                  {
                    value:
                      (pc.num_qualified_orders /
                        pc.reward_config_data.order_threshold) *
                      100,
                    color: '#0E7A4A',
                  },
                ]}
              />
              {pc.messages.settings_progress}
            </div>
          ))}
        </div>
      )}
    </div>
  ));

  return (
    <>
      <div className={styles.title}>Rewards Programs</div>
      {rewardsList}
    </>
  );
}
